import React, { useState, useEffect } from "react";
import blueLogo from "../../../assets/images/test-new-white-logo.svg";
import orangeLogo from "../../../assets/images/orange-logo.svg";
import wave from "../../../assets/images/tressback-ground.png";
import { Link, useNavigate } from "react-router-dom";
import "./SignIn.scss";
import { encryptionObj, decryption } from "../../../Encrypt";
import { userLogin, keyClock } from "../../../store/slices/login";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";

const SignIn = () => {
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**Function for checking logged in user */
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/homepage");
    }
  }, []);

  /**Function for displaying save details on toggle button */
  useEffect(() => {
    async function fetchMyAPI() {
      if (localStorage.getItem("save-email") !== null) {
        setEmail(await decryption(localStorage.getItem("save-email")));
      }
    }
    fetchMyAPI();
  }, []);

  /**Function for save details toggle*/
  const handleToggle = (e) => {
    setIsToggle(e.target.checked);
  };

  /**Function for Sign in */
  const handleSubmit = async (e) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    e.preventDefault();
    // if (isToggle) {
    //   if (email.length === 0 || !regex.test(email)) {
    //     setEmailError(true);
    //   } else if (password.length < 8) {
    //     setPasswordError(true);
    //   } else {
    //     let data = {
    //       email: email,
    //       password: password,
    //       device_id: "string",
    //       device_token: "string",
    //       roles: ["USER"],
    //     };
    //     let finalData = await encryptionObj(data);
    //     let payload = {
    //       data: finalData,
    //     };
    //     setIsLoading(true);
    //     dispatch(userLogin({ payload, setIsLoading, isToggle }));
    //   }
    // } else if (email.length === 0 || !regex.test(email)) {
    //   setEmailError(true);
    // } else if (password.length < 8) {
    //   setPasswordError(true);
    // } else {
    //   let data = {
    //     email: email,
    //     password: password,
    //     device_id: "string",
    //     device_token: "string",
    //     roles: ["USER"],
    //   };
    //   let finalData = await encryptionObj(data);
    //   let payload = {
    //     data: finalData,
    //   };
    setIsLoading(true);
    dispatch(keyClock(setIsLoading));
    // }
  };

  /**Funtion for handling email change */
  const handleEmail = (e) => {
    setEmailError(false);
    setPasswordError(false);
    setEmail(e.target.value);
  };

  /**Function for handling password change */
  const handlePassword = (e) => {
    setPasswordError(false);
    setPassword(e.target.value);
  };

  /**Function for show/hide password */
  const togglePassword = () => {
    setIsPasswordShowing((prev) => !prev);
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate('/sign-up')
  }

  return (
    <>
      {isLoading && (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      )}
      <div className="signin-form-wrapper">
        <div className="wave-wrap">
          <img src={wave} alt="wave" />
        </div>
        <div className="colored-background">
          <Link to="/" className="logo">
            <img src={blueLogo} alt="logo" />
          </Link>
        </div>
        <div className="right-signin-wrp">
          <form>
            <div className="signin-wrp block-signin-wrp">
              <Link to="/" className="logo">
                <img src={orangeLogo} alt="logo" />
              </Link>
              <div className="signin-form-wrp">
                <h1>Get Started</h1>
                <p>Your journey to wellness...</p>
                {/* <div className="form-wrap">
                  <div className="form-block">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      placeholder="abc@example.com"
                      class="form-control"
                      value={email}
                      onChange={(e) => handleEmail(e)}
                    />
                    {emailError ? (
                      <p className="error">Please enter a valid email</p>
                    ) : null}
                  </div>
                  <div className="form-block pass-wrp">
                    <label className="form-label">Password</label>
                    <div className="input-block">
                      <input
                        type={isPasswordShowing ? "text" : "password"}
                        placeholder="xxxxxxxx"
                        maxlength="25"
                        class="form-control"
                        onChange={(e) => handlePassword(e)}
                      />
                      <i
                        className={`icon-eye ${
                          isPasswordShowing ? "active" : ""
                        }`}
                        onClick={togglePassword}
                      ></i>
                      {passwordError ? (
                        <p className="error">
                          Password must be 8 characters long
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="forget-pass-block">
                    <Link to="/forget-password" className="forget-pass">
                      Forgot password?
                    </Link>
                  </div>

                  <div className="save-detail-wrp">
                    <div className="save-radio">
                      <label class="switch">
                        <input type="checkbox" onChange={handleToggle} />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <span className="save-text">Save Your Details</span>
                  </div>
                </div> */}
              </div>
              <div className="bottom-wrp">
                <button className="btn" onClick={handleSubmit}>
                  Sign in
                  <i className="icon-arrow">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                </button>
                <span className="sign-btn-divider">OR</span>
                <button className="btn" onClick={handleSignUp}>
                  Sign up
                  <i className="icon-arrow">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                </button>
                {/* <div className="no-account">
                  Don’t have an account?{" "}
                  <Link to="/sign-up" className="signup-now">
                    Sign up now
                  </Link>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignIn;
