import React, { useState, useEffect } from "react";
import "./Homepage.scss";
import Search from "../../components/HomepageData/Search/Search";
import HealthPlan from "../../components/HomepageData/HealthPlan/HealthPlan";
import QuickAccess from "../../components/HomepageData/QuickAccess/QuickAccess";
import ClaimIndex from "../../components/HomepageData/ClaimIndex/ClaimIndex";
import { ThreeDots } from "react-loader-spinner";
import {
  healthPlan,
  claimIndex,
  claimGraph,
} from "../../store/slices/homepage";
import { useDispatch, useSelector } from "react-redux";
import { quickAccess } from "../../store/slices/quickAccess";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios, { axiosInstanceKey } from "../../api/axios";
import { cardId, claimCount } from "../../store/slices/card";
import Footer from "../../components/Footer/Footer";

const USERINFO_URL = "/user";
const LOGOUT_URL = "/logout-account";
const EXCHANGE_CODE_URL = "/token";

const Homepage = () => {
  const [userData, setUserData] = useState("");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code = query.get("code"); // Get 'code' query param
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const claimesList = useSelector((state) => state.homepageSlice?.claims);
  const graphBar = useSelector((state) => state.homepageSlice?.graph);
  const profileView = useSelector((state) => state.profileSlice?.profileInfo);
  const cardDetails = useSelector((state) => state.cardSlice?.cards);
  const quickInfo = useSelector((state) => state.accessSlice?.accessInfo);
  const claimsCounts = useSelector((state) => state.cardSlice?.claimCounts);
  const date = new Date();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  /** Api's call on homepage display */
  useEffect(() => {
    setIsLoading(true);
    // dispatch(quickAccess(setIsLoading));
    const payload = {
      pageNumber: 0,
      recordsPerPage: 10
    }
    dispatch(claimIndex({ payload, setIsLoading }));
    dispatch(healthPlan(setIsLoading));
    dispatch(claimCount(setIsLoading));
    dispatch(claimGraph(setIsLoading));
    dispatch(cardId(setIsLoading));
  }, []);

  /** Function for putting 0 behind date less then 10 */
  function handleDay() {
    if (day < 10) {
      return "0" + day;
    } else {
      return day;
    }
  }

  /**Function for AM and PM */
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours < 10 ? "0" + hours || 12 : hours || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  /** Function to navigate to plans route on ID card icon click */
  const card = (e) => {
    e.preventDefault();
    navigate("/plans");
  };

  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        // wrapperClass=""
        />
      ) : (
        // )}
        <div className="home-page">
          {/* {success ? (
            <section>
              <span className="line">
                <a href="#" onClick={logoutUser}>
                  Logout
                </a>
              </span>
            </section>
          ) : (
            <section>Failed to fetch access token</section>
          )} */}
          <div className="homepage-content">
            <div className="header-banner">
              <div className="container">
                <h1>Hello, {profileView?.first_name}!</h1>
                <p>How can we help you today?</p>
              </div>
            </div>
            <div className="homepage-details">
              <div className="container">
                <div className="container-box">
                  <div className="detail-wrp">
                    <div className="left-block-wrp">
                      <div className="crd-main id-card" onClick={card}>
                        <div className="card-title-wrap">
                          <div className="icon-wrap">
                            <i className="icon-card">
                              <svg
                                width="53"
                                height="38"
                                viewBox="0 0 53 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.58984 3.24384H43.2173C45.0067 3.24384 46.4536 4.61915 46.4536 6.31146V7.8964"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.06664 31.5376H7.12892C5.33947 31.5376 3.89258 30.1623 3.89258 28.47V6.99152"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M48.5703 8.14691H12.1321C10.4379 8.14691 9.06445 9.52033 9.06445 11.2145V33.373C9.06445 35.0672 10.4379 36.4407 12.1321 36.4407H48.5703C50.2645 36.4407 51.638 35.0672 51.638 33.373V11.2145C51.638 9.52033 50.2645 8.14691 48.5703 8.14691Z"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M28.8184 21.353H47.8121"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M28.8184 24.9473H41.4774"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M20.5378 27.1405C22.4755 27.1405 24.0503 25.351 24.0503 23.1474C24.0503 20.9439 22.4806 19.1544 20.5378 19.1544C18.595 19.1544 17.0254 20.9439 17.0254 23.1474C17.0254 25.351 18.595 27.1405 20.5378 27.1405Z"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.9863 36.2567C12.9863 31.5172 16.3658 27.6776 20.5276 27.6776C24.6893 27.6776 28.0688 31.5172 28.0688 36.2567"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.25127 7.02205C5.69305 7.02205 6.86386 5.85124 6.86386 4.40945C6.86386 2.96767 5.69816 1.79175 4.25127 1.79175C2.80437 1.79175 1.63867 2.96256 1.63867 4.40434C1.63867 5.84612 2.80948 7.01693 4.25127 7.01693V7.02205Z"
                                  stroke="#281651"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </i>
                          </div>
                          <span className="id-card-title">ID Card</span>
                        </div>
                        <div className="card-name-wrap">
                          <span className="card-name id-card-name">
                            {cardDetails?.member_details?.name}
                          </span>
                          <span className="span-breaker id-card-breaker">:</span>
                          <span className="card-type">Insurance Cards</span>
                        </div>
                      </div>
                      <Link
                        className="crd-main provider-wrp"
                        to={"/claims"}
                      // target={claimesList?.length > 0 ? "_blank" : ""}
                      >
                        <div className="card-title-wrap">
                          <div className="icon-wrap">
                            <i className="icon-provider">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.36328 6.43175L5.36328 21.5681C5.36328 21.9071 5.05801 22.1818 4.68146 22.1818C4.33628 22.1818 4.05101 21.9509 4.00592 21.6514L3.99964 21.5681L3.99964 6.43175C3.99964 6.09284 4.30492 5.81812 4.68146 5.81812C5.02664 5.81812 5.31192 6.04897 5.3571 6.34849L5.36328 6.43175Z"
                                  fill="#C8F7F9"
                                />
                                <path
                                  d="M10.3633 21.5681L10.3633 6.43175L10.3571 6.34849C10.3119 6.04897 10.0266 5.81812 9.68146 5.81812C9.30492 5.81812 8.99964 6.09284 8.99964 6.43175L8.99964 21.5681L9.00583 21.6514C9.05101 21.9509 9.33628 22.1818 9.68146 22.1818C10.058 22.1818 10.3633 21.9071 10.3633 21.5681Z"
                                  fill="#C8F7F9"
                                />
                                <path
                                  d="M15.3618 21.5681L15.3618 6.43175L15.3556 6.34849C15.3105 6.04897 15.0252 5.81812 14.68 5.81812C14.3035 5.81812 13.9982 6.09284 13.9982 6.43175L13.9982 21.5681L14.0044 21.6514C14.0495 21.9509 14.3348 22.1818 14.68 22.1818C15.0565 22.1818 15.3618 21.9071 15.3618 21.5681Z"
                                  fill="#C8F7F9"
                                />
                                <path
                                  d="M22.7446 21.5179L22.7446 13.9933L22.7384 13.9032C22.6933 13.5791 22.408 13.3293 22.0628 13.3293C21.6863 13.3293 21.381 13.6266 21.381 13.9933L21.381 21.5179L21.3872 21.6081C21.4324 21.9321 21.7176 22.1819 22.0628 22.1819C22.4394 22.1819 22.7446 21.8846 22.7446 21.5179Z"
                                  fill="#C8F7F9"
                                />
                                <path
                                  d="M20.1253 7.75546C20.1253 8.82574 20.9924 9.6928 22.0627 9.6928C23.1329 9.6928 24 8.82574 24 7.75546C24 6.68518 23.1329 5.81812 22.0627 5.81812C20.9924 5.81812 20.1253 6.68518 20.1253 7.75546Z"
                                  stroke="#C8F7F9"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </i>
                          </div>
                          <span className="claim-title">Claims</span>
                        </div>
                        <div className="card-name-wrap">
                          <span className="claim-number">
                            {claimsCounts?.claim_count}{" "}
                            <span className="claim-word">Claims</span>
                          </span>
                          <span className="span-breaker">:</span>
                          <span className="claim-validity">Last 30 Days</span>
                        </div>
                      </Link>
                    </div>
                    {profileView?.is_provider_search_allowed && <Link to="/provider-list" className="crd-main find-provider-card" >
                      <div className="card-title-wrap find-provider-card-title-wrap">
                        <div className="icon-wrap">
                          <i className="find-provider-icon">

                          </i>
                        </div>
                        <div>

                          <span className="id-card-title">Find Provider</span>
                          {/* <span className="card-name id-card-name mobile-card-nm">
                          Lorem ipsum, your text goes here...
                          </span> */}
                       
                        </div>
                      </div>
                      <span className="card-name id-card-name desktop-card-nm">
                        Search for healthcare providers or facilities
                      </span>
                    </Link>}
                  </div>

                  <div className="banner-content home-health-plan">
                    <div className="report-content">
                      <div className="left-block">
                        <HealthPlan />
                      </div>
                      <ClaimIndex />
                      <QuickAccess />
                    </div>

                    {/* <FooterBanner /> */}
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Homepage;
