import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, {
  axiosInstanceAuth,
  axiosInstanceAuth2,
  axiosInstanceDelete,
} from "../../api/axios";
import endPoints from "../../api/endpoints";
import { encryption, decryption } from "../../Encrypt";
import { notify } from "../../NotifyToast";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**Api for showing user profile details */
export const viewProfile = createAsyncThunk(
  "viewProfile",
  async (_, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.profile)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          // let payload = {
          //   refresh_token: localStorage.getItem("refreshToken"),
          // };
          await thunkApi.dispatch(refreshToken());
          setTimeout(() => {
            notify.errorToast("Please sign in");
          }, 1500);
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          //   setIsLoading(false);
          thunkApi.dispatch(refreshToken());
        } else if (error?.response?.status === 401) {
          thunkApi.dispatch(refreshToken());
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("expiresIn");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("new_token");
          // window.location = "/";
        }
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

export const contactUs = createAsyncThunk(
  "contactUs",
  async ({payload,setContactMsg, setEmail, setMessage, setName, setIsLoading}, thunkApi) => {
    const response = axiosInstanceAuth
      .post(endPoints.contactUs, payload)
      .then(async (response) => {
        setIsLoading(false)
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          await thunkApi.dispatch(refreshToken());
        }
        if (response?.data?.code === 200) {
          setContactMsg(true);
          setEmail("");
          setMessage("");
          setName("");
        }
        else{
          notify.errorToast(response?.data?.response);
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          thunkApi.dispatch(refreshToken());
        }
        else{
          notify.errorToast(error?.response?.data?.response || "Something went wrong!");
        }
      });
    return response;
  }
);

/**Api for uploading profile picture */
export const profilePic = createAsyncThunk(
  "picUpload",
  async ({ payload, setIsLoading, setProfileUpdate }, thunkApi) => {
    let encode = encodeURIComponent(payload?.id);
    const response = axiosInstanceAuth
      .put(endPoints.uploadPic, payload)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
        }
        if (response?.data?.code === 200) {
          setIsLoading(false);
          setProfileUpdate(true);
          thunkApi.dispatch(viewProfile());
          return thunkApi.fulfillWithValue(response?.data?.response);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for deleting profile picture */
export const deleteProflePic = createAsyncThunk(
  "picDelete",
  async ({ payload, setIsLoading, setImage }, thunkApi) => {
    const response = axiosInstanceAuth
      .delete(endPoints.deleteImg, payload)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
        }
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(response?.data?.response);
        } else {
          setIsLoading(false);
          setImage(null);
          thunkApi.dispatch(viewProfile());
          return thunkApi.fulfillWithValue(response?.data?.response);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

export const accntDelete = createAsyncThunk(
  "accDelete",
  async ({ payload, setIsLoading, setDeleteConfirm }, thunkApi) => {
    const response = axiosInstanceDelete
      .delete(endPoints.deleteAccnt, { data: payload })
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
        }
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          notify.errorToast(response?.data?.response);
          return thunkApi.fulfillWithValue(response?.data?.resonse);
        } else {
          setIsLoading(false);
          notify.successToast(response?.data?.response);
          setDeleteConfirm(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
          return thunkApi.fulfillWithValue(response?.data?.response);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          setIsLoading(false);
          localStorage.removeItem("customer_token");
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewProfile.pending, (state, action) => {
    });
    builder.addCase(viewProfile.fulfilled, (state, action) => {
      state.profileInfo = action.payload;
    });
    builder.addCase(viewProfile.rejected, (state, action) => {
    });
  },
});

export default profileSlice.reducer;
