import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/test-new-white-logo.svg";
import "./Header.scss";

const HeaderCms = () => {
  return (
    <div className="header">
      <div className="header-wrap">
        <div className="container">
          <Link to="/" className="logo cms-logo">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderCms;
