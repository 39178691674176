import React, { useEffect, useState } from 'react';
import "./ProviderDetail.scss";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Footer from "../../components/Footer/Footer";
import TabMap from "../../assets/images/tab-map.jpg";
import TabMapDsktp from "../../assets/images/certi-map.jpg";
import CertifiIcon from "../../assets/images/certifi-icon-dsktp.png";
import { useDispatch } from 'react-redux';
import axiosInstance from '../../api/axios';
import endPoints from '../../api/endpoints';
import { decryptionObj, encryption } from '../../Encrypt';
import { notify } from '../../NotifyToast';
import { refreshToken } from '../../store/slices/refrshToken';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Practitioner from '../../components/Practitioner/Practitioner';
import Facility from '../../components/Facility/Facility';

const ProviderDetail = () => {
  const key = process.env.REACT_APP_MAP_KEY;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapError, setMapError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0, isSet: false });
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const lat = query.get("lat"); // Get 'location' query param
  const lon = query.get("lon"); // Get 'location' query param
  const zip= query.get("zip");

  useEffect(()=>{
    handleFetchList();
    setIsMapLoaded(true);
  }, [])

  const handleFetchList = async () => {
    const encId = await encryption(id || "");
    const encZip = await encryption(zip || "");
    // const encLocation = await encryption(location || "");
    setIsLoading(true);
    axiosInstance.get(`${endPoints.provider}?providerId=${encodeURIComponent(encId)}&zip=${encodeURIComponent(encZip)}`).then(async (response) => {
      setIsLoading(false);
      if (response.data.code === 200) {
        const decryptedData = await decryptionObj(response.data.response);
        if (decryptedData) {
          const parsedData = JSON.parse(decryptedData);
          setProvider(parsedData);
          // Extract coordinates from the provider list
          setMapCenter({
            lat: parseFloat(lat),
            lng: parseFloat(lon),
          });
        }
      } else {
        notify.errorToast(
          response.data.response ?? "Something went wrong!"
        );
      }
    }).catch((error) => {
      setIsLoading(false);
      if (
        error.response?.status === 401 ||
        error.response?.status === 403
      ) {
        dispatch(refreshToken());
      } else {
        notify.errorToast(
          error.response?.data?.response ??
          "Something went wrong!"
        );
      }
    })
  }

  const getDirections = () => {
    if (provider) {
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : null}
        <div className="provider-list-wrap provider-new-detail-wrapper">
          <div className="header-banner">
            <div className="container">
              <div className='header-backbtn-wrrped'>
              <h1>Provider details</h1>
              <button
                type="button"
                className="medium-card-back "
                onClick={()=>navigate(-1)}
              >
                <i className="medium-card-icon"></i>
                Back
              </button>
              </div>
            
            </div>
          </div>
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>navigate(-1)}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">Provider details</span>
            </div>
          </div>
          <div className="provider-detail-wrp">
            <div className="container">
              <div className="provider-detail-card">
                <div className='provider-list-inner-contnt-wrap'>
                  <div className='provider-data-nickname'>
                  <span>
                                          {
                                            provider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName?.charAt(0)}` : 
                                            provider?.Provider?.ProviderDetails?.Facility?.FacilityName?.charAt(0)
                                          }
                                        </span>
                  </div>
                  <div className='provider-list-data-wrap'>
                    <span className='provider-list-dsktp-wrpper'>
                      <span className='provider-list-data-name'>
                        <strong>{provider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} ${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}` : provider?.Provider?.ProviderDetails?.Facility?.FacilityName}</strong>
                      </span>
                      <span className='provider-list-data-desg'>{provider?.Provider?.ProviderType?.toLowerCase() === "p" ? provider?.Provider?.ProviderDetails?.Practitioner?.PrimaryDegree?.DegreeCode : provider?.Provider?.ProviderDetails?.Facility?.FacilityTypeList?.FacilityType?.map(d => d.FacilityTypeName).join(', ')}</span>
                    </span>

                    <p className='provider-list-data-address'>
                    {provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.AddressLine1}
                                          {provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.AddressLine2 && `, ${provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.AddressLine2}`}
                                          {provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.City && `, ${provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.City}`}
                                          {provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.State.stateName && `, ${provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.State?.stateName}`}
                                          {provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.Zipcode && `, ${provider?.Provider?.OfficeList?.Office?.[0]?.Address?.DomesticAddress?.Zipcode}`}
                                          <a href={`tel:${provider?.Provider?.OfficeList?.Office?.[0]?.PhoneList?.Phone?.[0]?.PhoneNumber}`} className='provider-list-data-number'>
                                            {provider?.Provider?.OfficeList?.Office?.[0]?.PhoneList?.Phone?.[0]?.PhoneNumber ?
                                              `(${provider?.Provider?.OfficeList?.Office?.[0]?.PhoneList?.Phone?.[0]?.PhoneNumber?.toString()?.slice(0, 3)}) ${provider?.Provider?.OfficeList?.Office?.[0]?.PhoneList?.Phone?.[0]?.PhoneNumber?.toString()?.slice(3, 6)}-${provider?.Provider?.OfficeList?.Office?.[0]?.PhoneList?.Phone?.[0]?.PhoneNumber?.toString()?.slice(6)}`
                                              : ''}
                                          </a>
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <Tabs>
                      <TabList>
                        <Tab>Overview</Tab>
                        {provider?.Provider?.ProviderDetails?.Facility?.CertificationList?.Certification?.length > 0 && <Tab>Certifications</Tab>}
                      </TabList>

                      <TabPanel>
                        <div>
                          <div className="message-container-wrap provider-deatils-tabbing-wrap">
                            <div className="tabbing-wrap">
                              <div className="tab-content">
                                <div className='overview-provider-details-data'>
                                  {
                                    provider?.Provider?.ProviderType?.toLowerCase() === "p" ? 
                                    <Practitioner provider={provider} />
                                    :
                                    <Facility provider={provider} />
                                  }

                                  {provider?.Provider?.ProviderDetails?.Practitioner?.HospitalAffiliationList?.HospitalAffiliation?.length > 0 && <div className='lang-expertise-whole-wrap'>
                                      <div className='overview-basic-details-data rmv-brdr-dsktp'>
                                        <h2>Hospital Affiliation</h2>
                                        <div className='overview-expertise-details-data'>
                                          {
                                            provider?.Provider?.ProviderDetails?.Practitioner?.HospitalAffiliationList?.HospitalAffiliation?.map((item)=>{
                                              return <span key={item?.Facility?.FacilityName}>{item?.Facility?.FacilityName}</span>
                                            })
                                          }
                                        </div>
                                      </div>
                                  </div>}
                                  <div className='lang-expertise-map-whole-wrap'>
                                    {(provider?.Provider?.ProviderDetails?.Practitioner?.LanguageList?.Language?.length > 0 || provider?.Provider?.ProviderDetails?.Practitioner?.SpecialityList?.Specialty?.length > 0) && <div className='lang-expertise-whole-wrap'>
                                      {provider?.Provider?.ProviderDetails?.Practitioner?.LanguageList?.Language?.length > 0 && <div className='overview-basic-details-data rmv-brdr-dsktp'>
                                        <h2>Language Spoken</h2>
                                        <div className='ovev-details-data'>
                                          <span>
                                            {
                                              provider?.Provider?.ProviderDetails?.Practitioner?.LanguageList?.Language?.map((item, index)=>{
                                                return index > 0 ? `, ${item?.LanguageName}` : item?.LanguageName 
                                              })
                                            }
                                          </span>
                                        </div>                         
                                      </div>}
                                      {provider?.Provider?.ProviderDetails?.Practitioner?.SpecialityList?.Specialty?.length > 0 && <div className='overview-basic-details-data rmv-brdr-dsktp'>
                                        <h2>Expertise</h2>
                                        <div className='overview-expertise-details-data'>
                                          {
                                            provider?.Provider?.ProviderDetails?.Practitioner?.SpecialityList?.Specialty?.map((item)=>{
                                              return <span key={item?.SpecialityName}>{item?.SpecialityName}</span>
                                            })
                                          }
                                        </div>
                                      </div>}
                                    </div>}
                                    <div className='overview-provider-map-data'>
                                      <div className='provider-detail-map-contnt'>
                                        <h2>Practice Locations</h2>
                                        <button onClick={getDirections} className='get-directions'>Get Directions</button>
                                      </div>
                                      <div className='provider-detail-map-wrp'>
                                        {isMapLoaded && provider && (
                                          <LoadScript
                                            googleMapsApiKey={key}
                                          // onLoad={handleMapLoad}
                                          // onError={handleMapError}
                                          >
                                            <GoogleMap
                                              mapContainerStyle={{
                                                width: '100%',
                                                height: '400px',
                                                border: '1px solid #ccc'
                                              }}
                                              center={mapCenter}
                                              zoom={11}
                                              options={{
                                                zoomControl: true,
                                                streetViewControl: false,
                                                mapTypeControl: false,
                                                fullscreenControl: false
                                              }}
                                            >
                                              <Marker
                                                position={{
                                                  lat: parseFloat(lat),
                                                  lng: parseFloat(lon)
                                                }}
                                                title={`${provider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} ${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}` : provider?.Provider?.ProviderDetails?.Facility?.FacilityName}`}
                                              />
                                            </GoogleMap>
                                          </LoadScript>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      {provider?.Provider?.ProviderDetails?.Facility?.CertificationList?.Certification?.length > 0 && <TabPanel>
                        <div className="message-container-wrap provider-deatils-tabbing-wrap certify-tabbing-wrp">
                          <div className="tabbing-wrap">
                            <div className="tab-content">
                              <div className='certifi-deatils-data'>
                                {
                                  provider?.Provider?.ProviderDetails?.Facility?.CertificationList?.Certification?.map((item)=>{
                                    return <div className='certifi-deatils-data-wrp' key={item?.CertifyingAgency}>
                                      <div className='certifi-deatils-data-img'>
                                        <img src={CertifiIcon} alt="certifi" />
                                      </div>
                                      <div className='certifi-deatils-data-cntnt'>
                                        <span className='certifi-deatils-data-cntnt-heading'>{item?.CertifyingAgency}</span>
                                        {/* <span className='certifi-deatils-data-cntnt-text'>Multi-board certified doctor, 2005</span> */}
                                      </div>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>}
                    </Tabs>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>

    </>
  )
}

export default ProviderDetail
