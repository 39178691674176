import React, { useState, useEffect } from "react";
import blueLogo from "../../../assets/images/test-new-white-logo.svg";
import orangeLogo from "../../../assets/images/orange-logo.svg";
import wave from "../../../assets/images/wave.svg";
import { Link } from "react-router-dom";
import "../SignIn/SignIn.scss";
import { encryptionObj, decryption } from "../../../Encrypt";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { passReset, checkLink } from "../../../store/slices/forgotPass";

const RestPass = () => {
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);
  const [isCnPasswordShowing, setCnIsPasswordShowing] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confrmPassword, setConfrmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confrmPasswordError, setConfrmPasswordError] = useState(false);
  const [confrmValidError, setConfrmValidError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const search = new URLSearchParams(window.location.search);
  const linkRes = useSelector((state) => state.forgotPasswordSlice?.chckLink);

  useEffect(() => {
    (async () => {
      if (search.get("id") !== null) {
        let window = search.get("id").replace(/\s/g, "+");
        let payload = {
          id: window,
          key: search.get("key"),
        };
        // return;
        setIsLoading(true);
        dispatch(checkLink({ payload, setIsLoading }));
      } else {
        window.location = "/";
      }
    })();
    return () => { };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_#*!&$])[A-Za-z\d@_#*!&$]{8,}$/;
    if (password.length < 8 || !passRegex.test(password)) {
      setPasswordError(true);
    } else if (confrmPassword.length < 8 || !passRegex.test(confrmPassword)) {
      setConfrmPasswordError(true);
    } else if (confrmPassword !== password) {
      setConfrmValidError(true);
    } else {
      if (search.get("id") !== null) {
        let window = search.get("id").replace(/\s/g, "+");
        let idd = await decryption(window);
        let newId = encodeURIComponent(search.get("id"));
        let data = {
          id: idd,
          key: search.get("key"),
          new_password: password,
          confirm_password: confrmPassword,
        };
        let finalData = await encryptionObj(data);
        let payload = {
          data: finalData,
        };
        setIsLoading(true);
        dispatch(passReset({ payload, setIsLoading }));
      } else {
        return;
      }
    }
  };

  const handlePassword = (e) => {
    setPasswordError(false);
    setPassword(e.target.value);
  };

  const handleConfrimPassword = (e) => {
    setConfrmPasswordError(false);
    setConfrmValidError(false);
    setConfrmPassword(e.target.value);
  };

  const togglePassword = () => {
    setIsPasswordShowing((prev) => !prev);
  };

  const toggleCnPassword = () => {
    setCnIsPasswordShowing((prev) => !prev);
  };

  return (
    <>
      {isLoading && (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      )}
      <div className="signin-form-wrapper">
        <div className="wave-wrap">
          <img src={wave} alt="wave" />
        </div>
        <div className="colored-background">
          <Link to="#" className="logo">
            <img src={blueLogo} alt="logo" />
          </Link>
        </div>
        <div className="right-signin-wrp">
          <form>
            <div className="signin-wrp">
              {linkRes?.code === 200 ? (
                <>
                  <Link to="#" className="logo">
                    <img src={orangeLogo} alt="logo" />
                  </Link>
                  <div className="signin-form-wrp">
                    <h1>Reset your password here!</h1>
                    <br />
                    <div className="form-wrap">
                      <div className="form-block pass-wrp">
                        {/* <div className="forget-pass-block"> */}
                        <label className="form-label">Password</label>
                        {/* </div> */}
                        <div className="input-block">
                          <input
                            type={isPasswordShowing ? "text" : "password"}
                            placeholder="xxxxxxxx"
                            maxlength="25"
                            class="form-control"
                            onChange={(e) => handlePassword(e)}
                          />
                          <i
                            className={`icon-eye ${isPasswordShowing ? "active" : ""
                              }`}
                            onClick={togglePassword}
                          ></i>
                        </div>
                        {passwordError ? (
                          <div className="error-wrap">
                            <div className="error lg-msz">
                              Password must be at least 8 characters long and include uppercase and lowercase letters, at least one number, and at least one special character (@, _, #, *, !, &, or $)
                            </div>
                          </div>
                        ) : null}
                        <br />
                        <div className="form-block pass-wrp">
                          {/* <div className="forget-pass-block"> */}
                          <label className="form-label">Confirm Password</label>
                          {/* </div> */}
                          <div className="input-block">
                            <input
                              type={isCnPasswordShowing ? "text" : "password"}
                              placeholder="xxxxxxxx"
                              maxlength="25"
                              class="form-control"
                              onChange={(e) => handleConfrimPassword(e)}
                            />
                            <i
                              className={`icon-eye ${isPasswordShowing ? "active" : ""
                                }`}
                              onClick={toggleCnPassword}
                            ></i>
                          </div>
                          {confrmPasswordError ? (
                            <div className="error-wrap">
                              <div className="error lg-msz">
                                Password must be at least 8 characters long and include uppercase and lowercase letters, at least one number, and at least one special character (@, _, #, *, !, &, or $)
                              </div>
                            </div>
                          ) : null}
                          {confrmValidError ? (
                            <p style={{ color: "red" }}>
                              Confirm paswword must be same as password
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-wrp">
                    <button className="btn" onClick={handleSubmit}>
                      Reset Password
                      <i className="icon-arrow">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                            fill="white"
                          />
                        </svg>
                      </i>
                    </button>
                  </div>
                </>
              ) : (
                <h2 className="link-check">{linkRes?.response}</h2>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RestPass;
