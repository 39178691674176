import React, { useEffect, useState } from "react";
import blueLogo from "../../../assets/images/test-new-white-logo.svg";
import orangeLogo from "../../../assets/images/orange-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import "../SignIn/SignIn.scss";
import "./SignUp.scss";
import wave from "../../../assets/images/tressback-ground.png";
import { decryption, encryptionObj } from "../../../Encrypt";
import { ThreeDots } from "react-loader-spinner";
import { userSignUp } from "../../../store/slices/signUp";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../api/axios";
import endPoints from "../../../api/endpoints";

const SignUp = () => {
  const dispatch = useDispatch();
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState({ value: "" });
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [agreedError, setAgreedError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState("");
  const [termsPopupActive, setTermsPopupActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get(endPoints.genericResources)
      .then(async (response) => {
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        setTerms(parsedData?.t_and_c?.content)
      })
      .catch((error) => {
      })
  }, [])

  /**Function for submit details on Sign up button */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
    const passRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_#*!&$])[A-Za-z\d@_#*!&$]{8,}$/;
    if (email?.trim()?.length === 0 || !regex.test(email?.trim())) {
      setEmailError(true);
    } else if (phone?.value?.length < 10 || phone?.value?.length > 12) {
      setPhoneError(true);
    } else if (password?.length < 8 || !passRegex.test(password)) {
      setPasswordError(true);
    } else if (!agreed) {
      setAgreedError(true);
    } else {
      let data = {
        email: email?.trim(),
        phone_number: phone?.value,
        password: password,
        first_name: firstName?.trim(),
        last_name: lastName?.trim(),
        role: "CUSTOMER",
        device_id: "web",
        device_token: "web",
      };
      let finalData = await encryptionObj(data);
      let payload = data;
      setIsLoading(true);
      dispatch(userSignUp({ payload, setIsLoading, navigate, finalData }));
    }
  };

  /**Function for allowing numbers only for phone number */
  function allowNumbersOnly(e) {
    let code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || (code > 57 && code < 96) || code > 105)) {
      e.preventDefault();
    }
  }

  /**Function for goin back to previous page */
  const goBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  /**Function for show/hide password */
  const togglePassword = () => {
    setIsPasswordShowing((prev) => !prev);
  };

  const handleMobile = (e) => {
    let value = e.target.value
    value = value.replaceAll(' ', '')
    setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)
    setPhoneError(false);
  }

  return (
    <>
      {isLoading && (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        // wrapperClass=""
        />
      )}
      <div className="signin-form-wrapper signup-wrp">
        <div className="wave-wrap">
          <img src={wave} alt="wave" />
        </div>
        <div className="colored-background">
          <Link to="/" className="logo">
            <img src={blueLogo} alt="logo" />
          </Link>
        </div>
        <div className="right-signin-wrp">
          <form>
            <div className="signin-wrp">
              <button className="bck-btn-wrap" onClick={goBack} type="button">
                <i className="icon-back"></i>
                Back
              </button>
              <Link to="/" className="logo">
                <img src={orangeLogo} alt="logo" />
              </Link>
              <div className="signin-form-wrp">
                <h1>Sign up</h1>
                <p>Your journey to wellness...</p>
                <div className="form-wrap">
                  <div className="form-block">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      value={email}
                      placeholder="abc@example.com"
                      class="form-control"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(false);
                      }}
                    />
                    {emailError ? (
                      <span className="error">Please enter a valid email</span>
                    ) : null}
                  </div>
                  <div className="form-block type-number">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      value={phone?.value}
                      placeholder="1234567890"
                      class="form-control"
                      name="phone"
                      onChange={handleMobile}
                      onKeyDown={(e) => {
                        allowNumbersOnly(e);
                        setPhoneError(false);
                      }}
                    />
                    {phoneError ? (
                      <span className="error">
                        Please enter a valid phone number
                      </span>
                    ) : null}
                  </div>

                  <div className="form-block pass-wrp">
                    <label className="form-label">Password</label>
                    <div className="input-block">
                      <input
                        type={isPasswordShowing ? "text" : "password"}
                        value={password}
                        placeholder="xxxxxxxx"
                        maxlength="25"
                        class="form-control"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordError(false);
                        }}
                      />
                      <i
                        className={`icon-eye ${isPasswordShowing ? "active" : ""
                          }`}
                        onClick={togglePassword}
                      ></i>
                    </div>
                    {passwordError ? (
                      <div className="error-wrap">
                        <div className="error lg-msz">
                          Password must be at least 8 characters long and include uppercase and lowercase letters, at least one number, and at least one special character (@, _, #, *, !, &, or $)
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-display-wrap">
                    <div className="form-block">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        maxlength="15"
                        value={firstName}
                        placeholder="John"
                        class="form-control"
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setFirstNameError(false);
                        }}
                      />
                      {firstNameError ? (
                        <span className="error">
                          Please enter the first name
                        </span>
                      ) : null}
                    </div>
                    <div className="form-block">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        maxlength="15"
                        value={lastName}
                        placeholder="Doe"
                        class="form-control"
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setLastNameError(false);
                        }}
                      />
                      {lastNameError ? (
                        <span className="error">
                          Please enter the last name
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="no-account agreed">
                <input type="checkbox" id="agreed" name="agreed" value={agreed} onChange={(e) => { setAgreed(e.target.checked); setAgreedError(false); }} />
                <div>
                  <label for="agreed"> I’ve read and agreed to the </label>
                  <button className="signup-now" type="button" onClick={() => setTermsPopupActive(true)}>Terms and Conditions</button>
                </div>
                {agreedError ? (
                  <span className="error abs-error">
                    Please agree terms and conditions
                  </span>
                ) : null}
              </div>
              <div className="bottom-wrp btn-edit">
                <button className="btn" onClick={handleSubmit}>
                  Sign up
                  <i className="icon-arrow">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                </button>
                <div className="no-account">
                  Already have an account?{" "}
                  <Link to="/" className="signup-now">
                    Sign in now
                  </Link>
                </div>
              </div>
            </div>
          </form>
          {termsPopupActive && <div className="popup delete-popup terms-popup">
            <div className="popup-wrap">
              <div className="poup-content">
                <button
                  className="close-popup"
                  onClick={() => setTermsPopupActive(false)}
                ></button>
                <div className="terms-content cms-page-content" dangerouslySetInnerHTML={{ __html: terms }}>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

export default SignUp;
