import React, { useState } from "react";
import "../MemberResource/MemberResource.scss";
import upload from "../../../assets/images/img-placeholder.svg";
import "../AccountSetting/AccountSetting.scss";
import "../AccountSettingForm/AccountSettingForm.scss";
import "./ChangePassword.scss";
import { Link, useNavigate } from "react-router-dom";
import { encryptionObj } from "../../../Encrypt";
import { useDispatch, useSelector } from "react-redux";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import { updatePass } from "../../../store/slices/forgotPass";
import { axiosInstanceLogout, axiosInstanceRefresh } from "../../../api/axios";

const ChangePassword = ({ setOpenPassword }) => {
  const dispatch = useDispatch();
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);
  const [isPasswordShowing1, setIsPasswordShowing1] = useState(false);
  const [isPasswordShowing2, setIsPasswordShowing2] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [oldPassError, setOldPassError] = useState(false);
  // const [oldPassLengthError, setOldPassLengthError] = useState(false);
  const [newPassError, setNewPassError] = useState(false);
  const [confPassError, setConfPassError] = useState(false);
  const [newPassReqError, setNewPassReqError] = useState(false);
  const [confPassReqError, setConfPassReqError] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [passMatch, setPassMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const profileView = useSelector((state) => state.profileSlice?.profileInfo);

  const LOGOUT_URL = "/logout-account";
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  /** Functions for view and hide password */
  const togglePassword = () => {
    setIsPasswordShowing((prev) => !prev);
  };
  const togglePassword1 = () => {
    setIsPasswordShowing1((prev) => !prev);
  };
  const togglePassword2 = () => {
    setIsPasswordShowing2((prev) => !prev);
  };

  const logoutUser = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");
    axiosInstanceLogout
      .get(LOGOUT_URL + "?session="+localStorage.getItem("sessionID"), {
      })
      .then((response) => {
        // Clear session storage or perform any other logout actions
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiresIn");
        localStorage.removeItem("idToken");
        localStorage.removeItem("new_token");
        sessionStorage.clear();
        // Redirect the user to the logout redirect URL
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("API call error:", error);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
      });
  };

  /** Function for change password from profile */
  const updatePassword = async (e) => {
    e.preventDefault();
    const passRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@_#*!&$])[A-Za-z\d@_#*!&$]{8,}$/;
    if (oldPass === "") {
      setOldPassError(true);
    } 
    // else if (!passRegex.test(oldPass)) {
    //   setOldPassLengthError(true);
    // } 
    else if (newPass?.length===0) {
      setNewPassReqError(true);
    } else if (!passRegex.test(newPass)) {
      setNewPassError(true);
    } else if (confPass?.length===0) {
      setConfPassReqError(true);
    } else if (!passRegex.test(confPass)) {
      setConfPassError(true);
    } else if (oldPass === newPass) {
      setMatchError(true);
    } else if (newPass !== confPass) {
      setPassMatch(true);
    } else {
      let data = {
        // email: profileView?.email,
        old_password: oldPass,
        new_password: newPass,
        confirm_password: confPass,
        logout_from_devices:isToggled
      };
      let finalData = await encryptionObj(data);
      let payload = {
        data: finalData,
      };
      setIsLoading(true);
      dispatch(updatePass({ payload, setOpenPassword, setIsLoading, logoutUser, isToggled }));
    }
  };


  return (
    <>
      {isLoading && (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          // wrapperClass=""
        />
      )}
      <div className="resource-content account-resource-content change-password">
        <div className="form-main-block">
          <div className="change-pass-head">
            <h2>Change Your Password</h2>
          </div>

          <div className="account-wrap">
            <form>
              <div className="signin-form-wrp">
                <div className="form-wrap">
                  <div className="form-block pass-wrp">
                    <label className="form-label">Enter Current Password</label>
                    <div className="input-block">
                      <input
                        type={isPasswordShowing ? "text" : "password"}
                        className="form-control"
                        value={oldPass}
                        maxlength="25"
                        placeholder="xxxxxxxxxx"
                        onChange={(e) => {
                          setOldPass(e.target.value);
                          setOldPassError(false);
                          // setOldPassLengthError(false);
                          setConfPassError(false);
                          setConfPassReqError(false);
                          setNewPassError(false);
                          setNewPassReqError(false);
                          setMatchError(false);
                        }}
                      />
                      <i
                        className={`icon-eye ${
                          isPasswordShowing ? "active" : ""
                        }`}
                        onClick={togglePassword}
                      ></i>
                    </div>
                    {oldPassError ? (
                      <span className="error">
                        Please enter current password
                        
                      </span>
                      
                    ) : null}
                    {/* {oldPassLengthError ? (
                      <div className="error-wrap">
                        <div className="error lg-msz">
                          1. Ensure that password must contain 8 characters,
                          both upper and lower case letters
                        </div>
                        <div className="error lg-msz">
                          2. Include symbols like @ , _ ,# ,* and numbers
                        </div>
                      </div>
                    ) : null} */}
                    
                  </div>
                  <div className="form-block pass-wrp">
                    <label className="form-label">Enter New Password</label>
                    <div className="input-block">
                      <input
                        type={isPasswordShowing1 ? "text" : "password"}
                        className="form-control"
                        value={newPass}
                        placeholder="xxxxxxxxxx"
                        maxlength="25"
                        onChange={(e) => {
                          setNewPass(e.target.value);
                          setNewPassError(false);
                          setNewPassReqError(false)
                          setMatchError(false);
                        }}
                      />
                      <i
                        className={`icon-eye ${
                          isPasswordShowing1 ? "active" : ""
                        }`}
                        onClick={togglePassword1}
                      ></i>
                    </div>
                    {newPassError ? (
                      <div className="error-wrap">
                        <div className="error lg-msz">
                          Password must be at least 8 characters long and include uppercase and lowercase letters, at least one number, and at least one special character (@, _, #, *, !, &, or $)
                        </div>
                      </div>
                    ) : null}
                    {newPassReqError ? (
                      <div className="error">
                          Please enter new password
                      </div>
                    ) : null}
                    {matchError ? (
                      <span className="error">
                        New password cannot be same as old password
                      </span>
                    ) : null}
                  </div>
                  <div className="form-block pass-wrp">
                    <label className="form-label">Confirm New Password</label>
                    <div className="input-block">
                      <input
                        type={isPasswordShowing2 ? "text" : "password"}
                        className="form-control"
                        value={confPass}
                        maxlength="25"
                        placeholder="xxxxxxxxxx"
                        onChange={(e) => {
                          setConfPass(e.target.value);
                          setConfPassError(false);
                          setConfPassReqError(false);
                          setPassMatch(false);
                        }}
                      />
                      <i
                        className={`icon-eye ${
                          isPasswordShowing2 ? "active" : ""
                        }`}
                        onClick={togglePassword2}
                      ></i>
                    </div>
                    {confPassError ? (
                      <div className="error-wrap">
                        <div className="error lg-msz">
                          Password must be at least 8 characters long and include uppercase and lowercase letters, at least one number, and at least one special character (@, _, #, *, !, &, or $)
                        </div>
                      </div>
                    ) : null}
                    {confPassReqError ? (
                      <div className="error">
                        Please enter new password
                      </div>
                    ) : null}
                    {passMatch ? (
                      <span className="error">
                        Confirm password must be same as new password
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="toggle-container">
                <div
                  className={`toggle-button ${isToggled ? 'toggled' : ''}`}
                  onClick={handleToggle}
                >
                  <div className="toggle-circle"></div>
                </div>
                <p>Logout from all devices</p>
              </div>
              <div className="update-wrp">
                <button
                  className="btn save"
                  type="submit"
                  onClick={updatePassword}
                >
                  Update
                  <i className="update-icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
