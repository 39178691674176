import React, { useEffect, useState } from 'react';
import "./ProviderList.scss";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import axiosInstance from '../../api/axios';
import endPoints from '../../api/endpoints';
import { decryptionObj, encryption, encryptionObj } from '../../Encrypt';
import { notify } from '../../NotifyToast';
import { useDispatch } from 'react-redux';
import { refreshToken } from '../../store/slices/refrshToken';
import ProviderSearch from '../ProviderSearch/ProviderSearch';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ReactPaginate from "react-paginate";
import searchProvider from "../../assets/images/quoting-vacant.png"
import noDataImg from "../../assets/images/list-nodata-img.png";


const ProviderList = () => {
  const navigate = useNavigate();
  const key = process.env.REACT_APP_MAP_KEY;
  const [searchActive, setSearchActive] = useState(true);
  const [providerList, setProviderList] = useState({});
  const [loading, setLoading] = useState(false);
  const [isToggled, setIsToggled] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0, isSet: false });
  const [distance, setDistance] = useState("15");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [service, setService] = useState("");
  const [location, setLocation] = useState("");

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    if(!searchActive){
      handleFetchList()
    }
  }, [distance, currentPage, itemsPerPage, searchActive])

  useEffect(() => {
    if (providerList?.TotalMatchingRecords) {
      setPageCount(Math.ceil(providerList.TotalMatchingRecords / itemsPerPage));
    }
  }, [providerList, itemsPerPage]);

  useEffect(() => {
    setIsMapLoaded(true);
    const serviceQuery = query.get("service");
    const locationQuery = query.get("location");
    if(serviceQuery && locationQuery){
      setService(serviceQuery);
      setLocation(locationQuery);
      setSearchActive(false);
    }
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    window.scrollTo(0, 0);
  };

  const handleFetchList = async () => {
    let data = {
      searchString: service,
      zipcode: location.replace('-', ''),
      distance: distance,
    };
    let finalData = await encryptionObj(data);
    let payload = {
      data: finalData,
    };
    setLoading(true);
    axiosInstance.post(`${endPoints.providerList}?page_number=${currentPage}&records_per_page=${itemsPerPage}`, payload).then(async (response) => {
      setLoading(false);
      if (response.data.code === 200) {
        const decryptedData = await decryptionObj(response.data.response);
        if (decryptedData) {
          const parsedData = JSON.parse(decryptedData);
          setSearchActive(false);
          if(parsedData){
            setProviderList(parsedData);
          }
          else{
            setProviderList({MatchingProviders: {MatchedProvider: []}})
          }
          // Extract coordinates from the provider list
          if (parsedData?.MatchingProviders?.MatchedProvider) {
            setMapCenter({
              lat: parseFloat(parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.Address?.DomesticAddress?.Latitude),
              lng: parseFloat(parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.Address?.DomesticAddress?.Longitude),
              name: `${parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.ProviderType?.toLowerCase() === "p" ? `${parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} ${parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}` : parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.ProviderDetails?.Facility?.FacilityName}`,
              address: parsedData.MatchingProviders.MatchedProvider?.[0]?.Provider?.Address?.DomesticAddress?.AddressLine1, isSet: true
            });
          }
        }
      } else {
        notify.errorToast(
          response.data.response ?? "Something went wrong!"
        );
      }
    }).catch((error) => {
      setLoading(false);
      if (
        error.response?.status === 401 ||
        error.response?.status === 403
      ) {
        dispatch(refreshToken());
      } else {
        notify.errorToast(
          error.response?.data?.response ??
          "Something went wrong!"
        );
      }
    })
  }

  const pageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMarkerClick = (coord, provider) => {
    setSelectedProvider(provider);
    setIsModalOpen(true);
  };

  const getDirections = () => {
    if (selectedProvider) {
      const lat = selectedProvider?.Provider?.Address?.DomesticAddress?.Latitude;
      const lng = selectedProvider?.Provider?.Address?.DomesticAddress?.Longitude;
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProvider(null);
  };

  const handleMapLoad = () => {
    
  };

  const handleMapError = (error) => {
    console.error('Error loading map:', error);
  };

  return (
    <>
      {loading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : null}
        <div>
          <div className="provider-list-wrap">
            <div className="header-banner">
              <div className="container">
                <div className='header-backbtn-wrrped'>
                  <h1>Provider List</h1>
                  <button
                  type="button"
                  className="medium-card-back new-medium-card-btn"
                  onClick={()=>navigate(-1)}
                >
                  <i className="medium-card-icon"></i>
                  Back
                </button>
                </div>
              </div>
            </div>
            <div className="card-headings">
              <div className="container">
                <span className="back-heading" onClick={()=>navigate(-1)}>
                  <i className="card-back-icon"></i>
                </span>
                <span className="card-head-text">{searchActive ? "Provider Search" : "Provider List"}</span>
              </div>
            </div>
            <div className="provider-list-wrp">
              <div className="container">
                <div className="provider-list-card">
                  <div className='provider-list-inner-wrp'>

                    <div className={`provider-list-wrp-whole ${searchActive ? "search-active" : ""} ${query.get("service") ? "":"list-invisible"}`}>
                      <ProviderSearch service={service} setService={setService} location={location} setLocation={setLocation} />
                    </div>
                    <div className={`listing-wrap ${searchActive ? "" : "list-active"}`}>
                      <div className='provider-search-list-wrapper prov-dsktp-list'>
                      {!isOpen && <div className='provider-list-search-result provider-list-search-result-mbl'>
                        <h2>Showing Results for:</h2>
                        <p>{query.get("service")} near {query.get("location")}</p>
                        <button onClick={()=>setSearchActive(true)} className='provider-edit-search'>Tap to Edit Search</button>
                      </div>}
                      {(query.get("service") && !isOpen) && <div className='prov-dsktp-list-search'>

                        <div className='provider-list-search-result popup-list-provider'>
                          <h2>Showing Results for:</h2>
                          <p>{query.get("service")} near {query.get("location")}</p>
                        </div>
                        <div className='provider-list-range-selector'>
                          <form>
                            <label for="options" className='form-label'>Select Radius</label>
                            <select onChange={(e) => { setDistance(e.target.value); if(currentPage>0){setCurrentPage(0);} }} value={distance} name="options" id="options" className='form-select'>
                              <option value="5">5 miles</option>
                              <option value="10">10 miles</option>
                              <option value="15">15 miles</option>
                              <option value="20">20 miles</option>
                              <option value="25">25 miles</option>
                              <option value="50">50 miles</option>
                              <option value="100">100 miles</option>
                            </select>
                            <i className='dropdown-icon'></i>
                          </form>
                        </div>
                      </div>}
                      {providerList?.MatchingProviders?.MatchedProvider?.length > 0 ? <div>
                      <div className="toggle-container">
                        <p>Map</p>
                        <div
                          className={`toggle-button ${isToggled ? 'toggled' : ''}`}
                          onClick={handleToggle}
                        >
                          <div className="toggle-circle"></div>
                        </div>

                      </div>
                      <div className='provider-list-map-cntnt-wrp'>

                        <div className='provider-list-customer-selection provider-dropdown-list'>

                          <div className="wrapper">
                            <div className="dropdown_c">

                              <div className="selected" onClick={toggleDropdown}>
                                <div className="line"></div>
                              </div>


                              <div className={`drop-content ${isOpen ? "open" : ""}`}>

                                <div className='prov-dsktp-list-search popup-list-mbl-provider'>
                                  <div className='provider-list-search-result provider-list-search-result-mbl'>
                                    <h2>Showing Results for:</h2>
                                    <p>{query.get("service")} near {query.get("location")}</p>
                                    <button onClick={()=>setSearchActive(true)} className='provider-edit-search'>Tap to Edit Search</button>
                                  </div>
                                  <div className='provider-list-range-selector'>
                                    <form>
                                      <label for="options" className='form-label'>Select Radius</label>
                                      <select onChange={(e) => { setDistance(e.target.value); if(currentPage>0){setCurrentPage(0);} }} value={distance} name="options" id="options" className='form-select'>
                                        <option value="5">5 miles</option>
                                        <option value="10">10 miles</option>
                                        <option value="15">15 miles</option>
                                        <option value="20">20 miles</option>
                                        <option value="25">25 miles</option>
                                        <option value="50">50 miles</option>
                                        <option value="100">100 miles</option>
                                      </select>
                                      <i className='dropdown-icon'></i>
                                    </form>
                                  </div>
                                </div>
                                <div className='provider-list-whole-contnt-wrap'>
                                  {providerList?.MatchingProviders?.MatchedProvider?.map((provider, index) => (
                                    <Link to={`/provider-detail/${provider?.Provider?.AlternateIDList?.AlternateIDNumber}?lat=${provider?.Provider?.Address?.DomesticAddress?.Latitude}&lon=${provider?.Provider?.Address?.DomesticAddress?.Longitude}&zip=${provider?.Provider?.Address?.DomesticAddress?.Zipcode}`} className='provider-list-inner-contnt-wrap' key={index}>
                                      <div className='provider-data-nickname'>
                                        <span>
                                          {
                                            provider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName?.charAt(0)}` : 
                                            provider?.Provider?.ProviderDetails?.Facility?.FacilityName?.charAt(0)
                                          }
                                        </span>
                                      </div>
                                      <div className='provider-list-data-wrap'>
                                        <span className='provider-list-data-innerwrp'>
                                          <span className='provider-list-data-name'><strong>{
                                            provider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} ${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}` : 
                                            provider?.Provider?.ProviderDetails?.Facility?.FacilityName
                                          }</strong></span>
                                          <span className='provider-list-data-desg'>{provider?.Provider?.ProviderType?.toLowerCase() === "p" ? provider?.Provider?.ProviderDetails?.Practitioner?.DegreeList?.Degree?.map(d => d.DegreeCode).join(', ') : provider?.Provider?.ProviderDetails?.Facility?.FacilityTypeList?.FacilityType?.map(d => d.FacilityTypeName).join(', ')}</span>
                                        </span>
                                        <p className='provider-list-data-address'>
                                          {provider?.Provider?.Address?.DomesticAddress?.AddressLine1}
                                          {provider?.Provider?.Address?.DomesticAddress?.AddressLine2 && `, ${provider?.Provider?.Address?.DomesticAddress?.AddressLine2}`}
                                          {provider?.Provider?.Address?.DomesticAddress?.City && `, ${provider?.Provider?.Address?.DomesticAddress?.City}`}
                                          {provider?.Provider?.Address?.DomesticAddress?.State.stateAbbreviation && `, ${provider?.Provider?.Address?.DomesticAddress?.State?.stateAbbreviation}`}
                                          {provider?.Provider?.Address?.DomesticAddress?.Zipcode && `, ${provider?.Provider?.Address?.DomesticAddress?.Zipcode}`}
                                          <a onClick={(e) => e.stopPropagation()} href={`tel:${provider?.Provider?.PhoneList?.Phone?.PhoneNumber}`} className='provider-list-data-number'>
                                            {provider?.Provider?.PhoneList?.Phone?.PhoneNumber ?
                                              `(${provider?.Provider?.PhoneList?.Phone?.PhoneNumber?.slice(0, 3)}) ${provider?.Provider?.PhoneList?.Phone?.PhoneNumber?.slice(3, 6)}-${provider?.Provider?.PhoneList?.Phone?.PhoneNumber?.slice(6)}`
                                              : ''}
                                          </a>
                                        </p>
                                      </div>
                                      <i className='drop-arrow-right'></i>
                                    </Link>
                                  ))}
                                  {providerList?.TotalMatchingRecords && (
                                    <div className="pagintion provider-list-pagination">
                                      <div className="entry-wrp">
                                        <span className="entry-text">show entries</span>
                                        <div className="select-wrap">
                                          <select value={itemsPerPage} onChange={pageChange}>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                          </select>
                                          <i className="icon-arrow"></i>
                                        </div>
                                      </div>
                                      <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=""
                                        onPageChange={handlePageClick}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={window.innerWidth < 767 ? 1 : 2}
                                        pageCount={pageCount}
                                        previousLabel=""
                                        renderOnZeroPageCount={null}
                                        className="pagination-list"
                                        forcePage={Math.min(currentPage, pageCount - 1)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>



                        </div>
                        {isToggled && <div className='provider-list-search-map'>
                          {isMapLoaded && providerList?.MatchingProviders?.MatchedProvider && (
                            <LoadScript
                              googleMapsApiKey={key}
                              onLoad={handleMapLoad}
                              onError={handleMapError}
                            >
                              <GoogleMap
                                mapContainerStyle={{
                                  width: '100%',
                                  minHeight: '400px',
                                  maxHeight: '700px',
                                  height: '100%',
                                  border: '1px solid #ccc'
                                }}
                                center={mapCenter}
                                zoom={(distance==="5" || distance==="10") ? 11 : (distance==="50" || distance==="100") ? 9 : 10}
                                options={{
                                  zoomControl: true,
                                  streetViewControl: false,
                                  mapTypeControl: false,
                                  fullscreenControl: false
                                }}
                              >
                                {providerList?.MatchingProviders?.MatchedProvider?.map((provider, index) => (
                                  <Marker
                                    key={index}
                                    position={{
                                      lat: parseFloat(provider?.Provider?.Address?.DomesticAddress?.Latitude),
                                      lng: parseFloat(provider?.Provider?.Address?.DomesticAddress?.Longitude)
                                    }}
                                    title={`${provider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} ${provider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}` : provider?.Provider?.ProviderDetails?.Facility?.FacilityName}`}
                                    onClick={() => handleMarkerClick({
                                      lat: parseFloat(provider?.Provider?.Address?.DomesticAddress?.Latitude),
                                      lng: parseFloat(provider?.Provider?.Address?.DomesticAddress?.Longitude)
                                    }, provider)}
                                  />
                                ))}
                              </GoogleMap>
                            </LoadScript>

                            )}
                            {isModalOpen && (
                              <div className="modal-overlay" onClick={closeModal}>
                                <div
                                  className="modal"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div className='modal-content'>
                                    <button className='close-btn' onClick={closeModal}><i className='close-icon'></i></button>
                                  </div>
                                  <div className='provider-list-inner-contnt-wrap'>
                                    <div className='provider-data-nickname'>
                                      <span>{selectedProvider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${selectedProvider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName?.charAt(0)}` : selectedProvider?.Provider?.ProviderDetails?.Facility?.FacilityName?.charAt(0)}</span>
                                    </div>
                                    <div className='provider-list-data-wrap'>
                                      <span className='provider-list-data-name drop-c-data-name'>
                                        <strong>{selectedProvider?.Provider?.ProviderType?.toLowerCase() === "p" ? `${selectedProvider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.FirstName} ${selectedProvider?.Provider?.ProviderDetails?.Practitioner?.PersonalDetails?.LastName}` : selectedProvider?.Provider?.ProviderDetails?.Facility?.FacilityName}</strong>
                                      </span>
                                      <span className='provider-list-data-desg'>{selectedProvider?.Provider?.PersonalDetails?.Designation}</span>
                                      <p className='provider-list-data-address'>
                                        {selectedProvider?.Provider?.Address?.DomesticAddress?.AddressLine1}
                                        {selectedProvider?.Provider?.Address?.DomesticAddress?.AddressLine2 && `, ${selectedProvider?.Provider?.Address?.DomesticAddress?.AddressLine2}`}
                                        {selectedProvider?.Provider?.Address?.DomesticAddress?.City && `, ${selectedProvider?.Provider?.Address?.DomesticAddress?.City}`}
                                        {selectedProvider?.Provider?.Address?.DomesticAddress?.State?.stateAbbreviation && `, ${selectedProvider?.Provider?.Address?.DomesticAddress?.State?.stateAbbreviation}`}
                                        {selectedProvider?.Provider?.Address?.DomesticAddress?.Zipcode && `, ${selectedProvider?.Provider?.Address?.DomesticAddress?.Zipcode}`}
                                        <a href={`tel:${selectedProvider?.Provider?.PhoneList?.Phone?.PhoneNumber}`} className='provider-list-data-number'>
                                          {selectedProvider?.Provider?.PhoneList?.Phone?.PhoneNumber ?
                                            `(${selectedProvider.Provider.PhoneList.Phone.PhoneNumber.slice(0, 3)}) ${selectedProvider.Provider.PhoneList.Phone.PhoneNumber.slice(3, 6)}-${selectedProvider.Provider.PhoneList.Phone.PhoneNumber.slice(6)}`
                                            : ''}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                  <div className='button-wrap'>
                                    <Link to={`/provider-detail/${selectedProvider?.Provider?.AlternateIDList?.AlternateIDNumber}?lat=${selectedProvider?.Provider?.Address?.DomesticAddress?.Latitude}&lon=${selectedProvider?.Provider?.Address?.DomesticAddress?.Longitude}&zip=${selectedProvider?.Provider?.Address?.DomesticAddress?.Zipcode}`} className='submit-btn'>View Details</Link>
                                    <button className='cancel-btn' onClick={getDirections}>Get Directions</button>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>}
                      </div>
                      </div>
                    : providerList?.MatchingProviders?.MatchedProvider?.length === 0 ? <div className='prov-list-no-data'>
                      <div className='no-dat-img-wrp'>
                        <img src={noDataImg} alt='noDataImg' />
                      </div>
                      <div className='no-dat-data-wrp'>
                      No data found
                      </div>
                    
                    </div> : null}</div> 
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>

    </>
  )
}

export default ProviderList
