import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import blueLogo from "../../../assets/images/test-new-white-logo.svg";
import orangeLogo from "../../../assets/images/orange-logo.svg";
import wave from "../../../assets/images/tressback-ground.png";
import { Popup } from "../Popup/Popup";
import { AuthLoading } from "../Popup/AuthLoading";
import "../SignIn/SignIn.scss";
import "./AuthInsight.scss";
import { insightVerify } from "../../../store/slices/signUp";
import { ThreeCircles } from "react-loader-spinner";
import { encryptionObj } from "../../../Encrypt";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthFail } from "../Popup/AuthFail";

const AuthInsight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [number, setNumber] = useState("");
  const [dob, setDob] = useState("");
  const [member, setMember] = useState("");
  const [group, setGroup] = useState("");
  const [numberError, setNumberError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [memberError, setMemberError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [openPopup, setOpenpopup] = useState(false);
  const [openPopupLoading, setOpenpopuploading] = useState(false);
  const [failPopup, setFailPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** Code for converting date in MM-DD-YYYY format */
  let dates = new Date(startDate);
  let days = dates.getDate();
  let months = dates.getMonth();
  days = dates.getDate() < 10 ? "0" + dates.getDate() : dates.getDate();
  months =
    dates.getMonth() < 9 ? "0" + (dates.getMonth() + 1) : dates.getMonth() + 1;
  let newdate = months + "-" + days + "-" + dates.getFullYear();

  useEffect(()=>{
    if(!localStorage.getItem("signUpEmail")){
      window.location.href = "/"
    }
  },[])

  /** Function for submit the form on continue button */
  const handleSubmit = async (e) => {
    const regex = /^\d{3}-\d{2}-\d{4}$/;
    e.preventDefault();
    if (number.length === 0 || !regex.test(number)) {
      setNumberError(true);
    } else if (startDate === null) {
      setDobError(true);
    } else {
      let data = {
        email: localStorage.getItem("signUpEmail"),
        phone_number: localStorage.getItem("signPhone"),
        identification_number: number,
        date_of_birth: startDate ? newdate : "",
        member_number: member?.trim(),
        group_number: group?.trim(),
      };
      let finalData = await encryptionObj(data);

      let payload = {data:finalData};
      // return;
      setOpenpopuploading(true);
      // setIsLoading(true);
      dispatch(
        insightVerify({
          payload,
          setIsLoading,
          setOpenpopup,
          setFailPopup,
          setOpenpopuploading,
        })
      );
    }
  };

  /** Function for managing SSN pattern on entering SSN number  */
  const handleTin = (e) => {
    let res = e.target.value
      .replace(/\D+/g, "") // Remove all non-digit characters
      .replace(/(\d{3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        // Ensure the proper format: XXX-XX-XXXX
        let result = p1; // First 3 digits
        if (p2) result += `-${p2}`; // Next 2 digits
        if (p3) result += `-${p3}`; // Last 4 digits
        return result;
      });
    setNumber(res); // Update the number state
    setNumberError(false); // Clear any errors
  };

  /** Function for going back to previous page */
  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      {/* {isLoading && (
        <ThreeCircles
          height="100"
          width="100"
          color=""
          wrapperStyle={{}}
          wrapperClass="loading-spin"
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor="#3498db"
          innerCircleColor="#007aff"
          middleCircleColor="#3498db"
        />
      )} */}
      {openPopupLoading && <AuthLoading />}
      {openPopup && <Popup />}
      {failPopup && <AuthFail />}
      <div className="signin-form-wrapper auth-insight">
        <div className="wave-wrap">
          <img src={wave} alt="wave" />
        </div>
        <div className="colored-background">
          <Link to="/" className="logo">
            <img src={blueLogo} alt="logo" />
          </Link>
        </div>
        <div className="right-signin-wrp">
          <form>
            <div className="signin-wrp">
              <button className="bck-btn-wrap" type="button" onClick={handleBack}>
                <i className="icon-back"></i>
                Back
              </button>
              <Link to="/" className="logo">
                <img src={orangeLogo} alt="logo" />
              </Link>
              <div className="signin-form-wrp">
                <h1>Verify Your Information</h1>
                <p>
                  In order to assist you in locating your account, we kindly
                  request a few additional details.
                </p>
                <div className="form-wrap">
                  <div className="form-block type-number">
                    <label className="form-label">
                      Social Security Number{" "}
                      <span className="label-text">- SSN</span>
                    </label>
                    <input
                      maxLength={11}
                      type="text"
                      value={number}
                      class="form-control"
                      placeholder="XXX-XX-XXXX"
                      onChange={(e) => {
                        handleTin(e);
                      }}
                    />
                    {numberError ? (
                      <p className="error">
                        Please enter valid social security number (XXX-XX-XXXX)
                      </p>
                    ) : null}
                  </div>
                  <div className="form-block select-dob">
                    <label className="form-label">Date of Birth </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        className="form-control"
                        value={startDate}
                        disableFuture={true}
                        onChange={(e) => {
                          setStartDate(e);
                          setDobError(false);
                        }}
                      />
                    </LocalizationProvider>
                    {dobError ? (
                      <p className="error">Please enter date of birth</p>
                    ) : null}
                  </div>
                  <div className="form-block">
                    <label className="form-label">
                      Member # <span className="label-text">- Optional</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e) => {
                        setMember(e.target.value);
                        setMemberError(false);
                      }}
                    />
                    {memberError ? (
                      <p className="error">Please enter member name</p>
                    ) : null}
                  </div>
                  <div className="form-block">
                    <label className="form-label">
                      Group # <span className="label-text">- Optional</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e) => {
                        setGroup(e.target.value);
                        setGroupError(false);
                      }}
                    />
                    {groupError ? (
                      <p className="error">Please enter group</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="bottom-wrp">
                <button className="btn" type="submit" onClick={handleSubmit}>
                  Continue
                  <i className="icon-arrow">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AuthInsight;
