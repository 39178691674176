import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import blueLogo from "../../../assets/images/test-new-white-logo.svg";
import orangeLogo from "../../../assets/images/orange-logo.svg";
import wave from "../../../assets/images/tressback-ground.png";
import { encryption } from "../../../Encrypt";
import "../SignIn/SignIn.scss";
import "./ForgetPassword.scss";
import { resetLink } from "../../../store/slices/forgotPass";
import { useDispatch } from "react-redux";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    // const regex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    e.preventDefault();

    if (email.length === 0) {
      setEmailError(true);
    } else {
      let encode = await encryption(email);
      let payload = encodeURIComponent(encode);
      dispatch(resetLink(payload));
    }
  };

  return (
    <div className="signin-form-wrapper forget-password-form forget-nw">
      <div className="wave-wrap">
        <img src={wave} alt="wave" />
      </div>
      <div className="colored-background">
        <Link to="/" className="logo">
          <img src={blueLogo} alt="logo" />
        </Link>
      </div>
      <div className="right-signin-wrp">
        <form>
          <div className="signin-wrp block-signin-wrp">
            <button className="bck-btn-wrap" onClick={handleBack} type="button">
              <i className="icon-back"></i>
              Back
            </button>
            <Link to="/" className="logo">
              <img src={orangeLogo} alt="logo" />
            </Link>
            <div className="signin-form-wrp">
              <h1>Forgot Password?</h1>
              <p>
                Enter your email that you used to register your account, so we
                can send you a link to reset your password.
              </p>
              <div className="form-wrap">
                <div className="form-block">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    placeholder="abc@example.com"
                    class="form-control"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                    }}
                  />
                  {emailError ? (
                    <p className="error">Please enter a valid email</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="bottom-wrp">
              <button className="btn" onClick={handleSubmit} type="submit">
                Send Request{" "}
                <i className="icon-arrow">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                      fill="white"
                    />
                  </svg>
                </i>
              </button>
            </div>
          </div>
        </form >
      </div >
    </div >
  );
};

export default ForgetPassword;
