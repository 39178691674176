import React, { useEffect, useState } from "react";
import "./CardData.scss";
import { Link } from "react-router-dom";
import healthLogo from "../../../assets/images/health-icon.svg";
import healthLogoTablet from "../../../assets/images/tablet-health-logo.svg";
import { useSelector } from "react-redux";
import blueLogo from "../../../assets/images/test-new-white-logo.svg";
import cigna from "../../../assets/images/cigna.png";
import { ThreeDots } from "react-loader-spinner";

const CardData = () => {
  const cardDetails = useSelector((state) => state.cardSlice?.cards);
  const healthCardDetails = useSelector(
    (state) => state?.healthCardSlice?.healthCardInfo
  );
  const pdfDetails = useSelector((state) => state.pdfSlice?.filesPdf);

  const [rotateImage, setRotateImage] = useState(true);
  // const [isLoading, setIsLoading] = useState(false)

  // useEffect(()=>{
  //   if(healthCardDetails?.png?.front_url && healthCardDetails?.png?.back_url){
  //     setIsLoading(true)
  //   }
  // },[healthCardDetails])

  /** Function to convert date into long format */
  let dates = cardDetails?.member_details?.date_of_birth;
  let date2 = new Date(dates);
  const date = date2;
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();


  return (
    <>
      {/* {
        isLoading && <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#240151"
        wrapperClass="loading-spin"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
      />
      } */}
      <div className="plan-content">
        <div className="card-banner">
          <div className="idcard-wrap">
            {(healthCardDetails?.png?.front_url && healthCardDetails?.png?.back_url) ? <div className="mobile-id-card">
              <a href={rotateImage ? healthCardDetails?.png?.front_url : healthCardDetails?.png?.back_url} target="_blank" className="img-id-card">
                <img src={rotateImage ? healthCardDetails?.png?.front_url : healthCardDetails?.png?.back_url} alt="id-card"/>
              </a>
              <div className="icons-wrap-card">
                <a target="_blank" href={healthCardDetails?.pdf_url}><i className="reverse-icon"></i></a>
                <button type="button" onClick={()=>setRotateImage(prev=>!prev)} className="download-card-btn"><i className="downld-icon"></i></button>
              </div>
            </div> : 
            <div className="card-box lg-card-box">
              <div className="card-head">
                <div className="head-wrp">
                  <h3>{cardDetails?.member_details?.name}</h3>
                  <p>Medical Insurance Card</p>
                </div>
                <div className="card-logo-wrp">
                  <img src={blueLogo} alt="logo" />
                </div>
              </div>
              <div className="data-main-wrp initial-box">
                <div className="card-content first-card-content">
                  <div className="head-wrp dob">
                    <h3>Date of Birth</h3>
                    <p>{cardDetails?.member_details?.date_of_birth}</p>
                  </div>
                  <div className="head-wrp effective-date">
                    <h3>Effective Date</h3>
                    <p>{cardDetails?.member_details?.effective_date}</p>
                  </div>
                  <div className="head-wrp coverage">
                    <h3>Coverage</h3>
                    <p>
                      {cardDetails?.member_details?.coverage
                        ? cardDetails?.member_details?.coverage
                        : "- -"}
                    </p>
                  </div>
                  <div className="head-wrp person">
                    <h3>Person</h3>
                    <p>{cardDetails?.member_details?.person}</p>
                  </div>
                  <div className="head-wrp grp-no grp-one">
                    <h3>Member ID#</h3>
                    <p>{cardDetails?.member_details?.member_number}</p>
                  </div>
                  <div className="head-wrp grp-no grp-two">
                    <h3>Group Number</h3>
                    <p>{cardDetails?.member_details?.group_number}</p>
                  </div>
                </div>
              </div>
            </div>
            }
            
          </div>
        
          <div className="card-line"></div>
          <div className="card-box card-one card-box-main">
            <h2>Prescription Benefits</h2>
            <div className="card-content-wrpper above-card">
              <div className="card-one-wrp">
                <div className="member-content">
                  <div className="head-wrp first-head">
                    <h3>
                      <i className="icon-sign">
                        <svg
                          width="9"
                          height="11"
                          viewBox="0 0 9 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id="Vector"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.361328 8.91861V8.04386C0.931354 7.8554 0.917796 7.8554 0.944912 7.69389L0.972086 1.85272C0.972086 1.5028 0.917796 1.4893 0.456351 1.39507V0.520264H3.67284C4.97571 0.520264 5.60003 0.574102 6.18361 0.950971C6.84866 1.38168 7.18791 2.12192 7.18791 2.79487C7.18791 3.95237 6.5093 4.51764 5.13858 4.98869C5.6407 5.39245 5.77646 5.54057 6.19717 6.06546L7.05215 4.9618L7.85296 5.56746L6.8351 6.95365C7.33722 7.78812 7.54077 7.94969 8.16509 8.03036V8.91873H6.18367C5.99362 8.91873 5.96901 8.89963 5.8463 8.79755C5.67893 8.66603 5.69574 8.66603 5.58647 8.58225L4.10717 10.5203L3.30642 9.92798L4.93498 7.69383C4.555 6.83241 4.25643 6.29409 3.70002 5.63458C3.36533 5.22895 3.2114 5.16359 2.83142 5.16359V7.38436C2.83142 7.88229 2.84498 7.97652 3.38783 8.00347V8.91861H0.361328ZM5.12668 2.83684C5.12668 1.80047 4.36661 1.55822 2.83302 1.55822V4.10196C3.07735 4.11541 3.30802 4.11541 3.45733 4.11541C4.54309 4.11546 5.12668 3.64436 5.12668 2.83684Z"
                            fill="#281651"
                          />
                        </svg>
                      </i>
                      PCN
                    </h3>
                    <p>{cardDetails?.prescription_benefits_details?.rx_pcn}</p>
                  </div>
                  <div className="head-wrp">
                    <h3>
                      <i className="icon-sign">
                        <svg
                          width="9"
                          height="11"
                          viewBox="0 0 9 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id="Vector"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.361328 8.91861V8.04386C0.931354 7.8554 0.917796 7.8554 0.944912 7.69389L0.972086 1.85272C0.972086 1.5028 0.917796 1.4893 0.456351 1.39507V0.520264H3.67284C4.97571 0.520264 5.60003 0.574102 6.18361 0.950971C6.84866 1.38168 7.18791 2.12192 7.18791 2.79487C7.18791 3.95237 6.5093 4.51764 5.13858 4.98869C5.6407 5.39245 5.77646 5.54057 6.19717 6.06546L7.05215 4.9618L7.85296 5.56746L6.8351 6.95365C7.33722 7.78812 7.54077 7.94969 8.16509 8.03036V8.91873H6.18367C5.99362 8.91873 5.96901 8.89963 5.8463 8.79755C5.67893 8.66603 5.69574 8.66603 5.58647 8.58225L4.10717 10.5203L3.30642 9.92798L4.93498 7.69383C4.555 6.83241 4.25643 6.29409 3.70002 5.63458C3.36533 5.22895 3.2114 5.16359 2.83142 5.16359V7.38436C2.83142 7.88229 2.84498 7.97652 3.38783 8.00347V8.91861H0.361328ZM5.12668 2.83684C5.12668 1.80047 4.36661 1.55822 2.83302 1.55822V4.10196C3.07735 4.11541 3.30802 4.11541 3.45733 4.11541C4.54309 4.11546 5.12668 3.64436 5.12668 2.83684Z"
                            fill="#281651"
                          />
                        </svg>
                      </i>
                      BIN
                    </h3>
                    <p>{cardDetails?.prescription_benefits_details?.rx_bin}</p>
                  </div>
                  <div className="head-wrp last-head">
                    <h3>
                      <i className="icon-sign">
                        <svg
                          width="9"
                          height="11"
                          viewBox="0 0 9 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id="Vector"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.361328 8.91861V8.04386C0.931354 7.8554 0.917796 7.8554 0.944912 7.69389L0.972086 1.85272C0.972086 1.5028 0.917796 1.4893 0.456351 1.39507V0.520264H3.67284C4.97571 0.520264 5.60003 0.574102 6.18361 0.950971C6.84866 1.38168 7.18791 2.12192 7.18791 2.79487C7.18791 3.95237 6.5093 4.51764 5.13858 4.98869C5.6407 5.39245 5.77646 5.54057 6.19717 6.06546L7.05215 4.9618L7.85296 5.56746L6.8351 6.95365C7.33722 7.78812 7.54077 7.94969 8.16509 8.03036V8.91873H6.18367C5.99362 8.91873 5.96901 8.89963 5.8463 8.79755C5.67893 8.66603 5.69574 8.66603 5.58647 8.58225L4.10717 10.5203L3.30642 9.92798L4.93498 7.69383C4.555 6.83241 4.25643 6.29409 3.70002 5.63458C3.36533 5.22895 3.2114 5.16359 2.83142 5.16359V7.38436C2.83142 7.88229 2.84498 7.97652 3.38783 8.00347V8.91861H0.361328ZM5.12668 2.83684C5.12668 1.80047 4.36661 1.55822 2.83302 1.55822V4.10196C3.07735 4.11541 3.30802 4.11541 3.45733 4.11541C4.54309 4.11546 5.12668 3.64436 5.12668 2.83684Z"
                            fill="#281651"
                          />
                        </svg>
                      </i>
                      Group
                    </h3>
                    <p>{cardDetails?.prescription_benefits_details?.rx_group}</p>
                  </div>
                </div>
                <h2>Pharmacy Network</h2>
              </div>
              <div className="card-content new-card-content">
                <div className="head-wrp">
                  <div className="logo-wrap">
                    <div className="logo-head">
                      <img
                        src={cigna}
                        alt="logo"
                      ></img>
                    </div>
                    <div className="logo-body">Cigna</div>
                  </div>
                </div>
              </div>
              <div className="card-provider-wrp">
                <div className="card-content">
                  <div className="head-wrp">
                    <h2>Plan Name</h2>
                    <p>{cardDetails?.plan_name}</p>
                  </div>
                </div>
                <h2>Provider Networks</h2>
                <div className="member-content">
                  {cardDetails?.provider_networks_details?.map((item, index) => {
                    return (
                      item?.network_url ? <Link
                        to={item?.network_url}
                        target={"_blank"}
                        className="logo-wrap"
                        key={index}
                      >
                        <div className="logo-head">
                          <img src={item?.image_url} alt="logo"></img>
                        </div>
                        <div className="logo-body">{item?.networks_name}</div>
                      </Link>:
                      <div
                        className="logo-wrap"
                        key={index}
                      >
                        <div className="logo-head">
                          <img src={item?.image_url} alt="logo"></img>
                        </div>
                        <div className="logo-body">{item?.networks_name}</div>
                      </div>
                    );
                  })}
                  <div className="desk-logo">
                    <picture>
                      <source media="(min-width:1280px)" srcset={healthLogo} />
                      <source
                        media="(min-width:768px)"
                        srcset={healthLogoTablet}
                      />
                      <img src={healthLogo} alt="healthLogo" />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="card-line"></div>
          <div className="card-box card-two">
            <div className="card-head">
              <div className="head-wrp">
                <h3>Health Benefit Documents</h3>
              </div>
              <div className="download-main-wrap">
                {/* <a
                  href={healthCardDetails?.pdf_url}
                  to="#"
                  className="download-wrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="download-icon"></i>Download ID Card
                </a> */}
                {pdfDetails?.documents?.length > 0 ? (
                  pdfDetails?.documents?.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item?.file_url}
                        to="#"
                        className="download-wrap"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="pdf-icon"></i>
                        <span className="benefit-text">{item?.file_name}</span>
                        <i className="arrow-icon"></i>
                      </a>
                    );
                  })
                ) : (
                  <div className="no-docs-text">No documents</div>
                )}
                {/* <a
                  href={pdfDetails?.documents?.[0]?.file_url}
                  to="#"
                  className="download-wrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="pdf-icon"></i>
                  <span className="benefit-text">
                    {pdfDetails?.documents?.[0]?.file_name}
                  </span>
                  <i className="arrow-icon"></i>
                </a>
                <a
                  href={pdfDetails?.documents?.[1]?.file_url}
                  to="#"
                  className="download-wrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="pdf-icon"></i>
                  <span className="benefit-text">
                    {pdfDetails?.documents?.[1]?.file_name}
                  </span>
                  <i className="arrow-icon"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardData;
