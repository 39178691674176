import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountSetting from "../../components/MemeberData/AccountSetting/AccountSetting";
import InsuranceBenefits from "../../components/MemeberData/InsuranceBenefits/InsuranceBenefits";
import MemberResource from "../../components/MemeberData/MemberResource/MemberResource";
import "./MemberScreen.scss";
import PasswordChange from "../../components/MemeberData/ChangePass";
import SecuritySeting from "../../components/MemeberData/SecuritySeting/SecuritySeting";
import ClaimPermissions from "../../components/MemeberData/ClaimPermissions/ClaimPermissions";
import { insurance } from "../../store/slices/memberResource";
import { ThreeDots } from "react-loader-spinner";
import upload from "../../assets/images/img-placeholder.svg";
import tressMember from "../../assets/images/test-new-blue-logo.svg";
import memberImage from "../../assets/images/member-image.png";
import axios, { axiosInstanceLogout, axiosInstanceRefresh } from "../../api/axios";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const LOGOUT_URL = "/logout-account";

const MemberScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [tabData, setTabData] = useState(window.innerWidth > 767 ? 1 : null);
  const profileView = useSelector((state) => state.profileSlice?.profileInfo);
  const [info, setInfo] = useState(false);

  const [claimData, setClaimData] = useState(null);

  /** Function for api call for insurance data */
  // useEffect(() => {
  //   setIsLoading(true);
  //   dispatch(insurance(setIsLoading));
  // }, []);

  const clientId = process.env.REACT_APP_CLIENT_ID;

  /** Function for account logout */
  const handleLogout = (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");
    axiosInstanceLogout
      .get(LOGOUT_URL+ "?session="+localStorage.getItem("sessionID"), {
      })
      .then((response) => {
         localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiresIn");
        localStorage.removeItem("idToken");
        localStorage.removeItem("new_token");
        sessionStorage.clear();
        // Redirect the user to the logout redirect URL
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("API call error:", error);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : (
        <div className="member-screen">
          <div className="header-banner">
            <div className="container">
              <h1>
                Member{" "}
                <span className="member-heading">
                  .{" "}
                  {tabData === 1
                    ? "Account Details"
                    : tabData == 3
                    ? "Member Resources"
                    : tabData == 4
                    ? "Change Password"
                    : tabData == 2
                    ? "Insurance & Benefits"
                    : tabData == 6
                    ? "Claim Sharing Permissions"
                    : null}
                </span>
              </h1>
            </div>
          </div>
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>setTabData(null)}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">Member</span>
            </div>
          </div>
          <div className="container">
            <div className="member-content-card">
              <div className="member-content">
                <div className="tab-wrp">
                  <div className="mob-member-details">
                    <div className="logo-wrap">
                      <img src={tressMember} alt="tress-logo" />
                    </div>
                    <div className="member-profile-img">
                      <div className="member-image-block">
                        <img src={profileView?.profile_pic_url
                        ? profileView?.profile_pic_url
                        : upload} alt="profile" />
                      </div>
                      <div className="member-profile-name">
                        <span className="member-profile-name">{profileView?.first_name} {profileView?.last_name}</span>
                        <span className="member-profile-email">
                          {profileView?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="tab-head-wrap">
                    <button
                      onClick={() => setTabData(1)}
                      className={`tab-head ${tabData == 1 ? "active" : ""}`}
                    >
                      <div className="main-head">
                        <i className="icon-account"></i>
                        Account Details
                      </div>
                      <i className="icon-arrow"></i>
                    </button>
                    <button
                      onClick={() => setTabData(4)}
                      className={`tab-head change-password ${
                        tabData == 4 ? "active" : ""
                      }`}
                    >
                      <div className="main-head">
                        <i className="icon-account"></i>
                        Change Password
                      </div>
                      <i className="icon-arrow"></i>
                    </button>
                    <button
                      disabled={claimData?.length===0}
                      onClick={() => setTabData(6)}
                      className={`tab-head change-password ${
                        tabData == 6 ? "active" : ""
                      }`}
                    >
                      <div className="main-head">
                        <i className="icon-account"></i>
                        Claim Sharing Permissions
                      </div>
                      <i className="icon-arrow"></i>
                    </button>
                    <button
                      // onClick={() => setTabData(4)}
                      onClick={()=>{setInfo(prev=>!prev)}}
                      className="tab-head more-info"
                    >
                      <div className="main-head">
                        <i className="icon-account"></i>
                        More Info
                      </div>
                      <i className="icon-arrow"></i>
                    </button>
                    {info && <ul className="cms-links">
                      <li>
                        <Link target='_blank' to="/contact-us">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About us</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">Terms & conditions</Link>
                      </li>
                    </ul>}
                    {/* <button
                      onClick={() => setTabData(5)}
                      className={`tab-head  security-tab ${
                        tabData == 5 ? "active" : ""
                      }`}
                    >
                      <div className="main-head">
                        <i className="icon-account"></i>
                        Security Settings
                      </div>
                      <i className="icon-arrow"></i>
                    </button> */}
                    {/* <button
                      onClick={() => setTabData(3)}
                      className={`tab-head ${tabData == 3 ? "active" : ""}`}
                    >
                      <div className="main-head">
                        <i className="icon-member"></i>
                        Member Resources
                      </div>
                      <i className="icon-arrow"></i>
                    </button> */}
                    {/* <button
                      onClick={() => setTabData(2)}
                      className={`tab-head ${tabData == 2 ? "active" : ""}`}
                    >
                      <div className="main-head">
                        <i className="icon-insurance"></i>
                        Insurances & Benefits
                      </div>
                      <i className="icon-arrow"></i>
                    </button> */}

                    <button className="tab-head logout" onClick={handleLogout}>
                      <div className="main-head">
                        <i className="icon-logout"></i>
                        Logout
                      </div>
                      <i className="icon-arrow"></i>
                    </button>
                  </div>
                  {/* <div
                    className={`tab-popup in ${tabData === 3 ? "active" : ""}`}
                  >
                    <MemberResource setTabData={setTabData} />
                  </div> */}
                  {/* <div
                    className={`tab-popup out ${tabData === 2 ? "active" : ""}`}
                  >
                    <InsuranceBenefits setTabData={setTabData} />
                  </div> */}
                  <div
                    className={`tab-popup over ${
                      tabData === 1 ? "active" : ""
                    }`}
                  >
                    <AccountSetting setTabData={setTabData} />
                  </div>
                  <div
                    className={`tab-popup over ${
                      tabData === 4 ? "active" : ""
                    }`}
                  >
                    <PasswordChange setTabData={setTabData} />
                  </div>
                  <div
                    className={`tab-popup over ${
                      tabData === 5 ? "active" : ""
                    }`}
                  >
                    <SecuritySeting setTabData={setTabData} />
                  </div>
                  <div
                    className={`tab-popup over ${
                      tabData === 6 ? "active" : ""
                    }`}
                  >
                    <ClaimPermissions setTabData={setTabData} claimData={claimData} setClaimData={setClaimData}/>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemberScreen;
